<template>
  <div class="kb" id="kb">
    <!-- begin::kb-header -->
    <header class="kb-header" id="kb-header">
      <div class="header-component">
        <!-- header-util -->
        <div class="header-column header-util left">
          <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="goBef">
              <i class="icon-history-back"></i>
            </a>
          </div>
        </div>
        <!-- header-title -->
        <div class="header-column header-title">
          {{ targetCertification.name }} – 교과목
        </div>
        <!-- //header-title -->
      </div>
    </header>
    <!-- end::kb-header -->
    <!-- begin::kb-main -->
    <main class="kb-main" id="kb-mypage">
      <!-- main-content -->
      <div class="main-content min-component">
        <div class="mypage-body">
          <!-- content-section:연수이력 -->
          <article class="content-section pt-0">
            <div class="text-item-container">
              <template v-if="targetCertification.courses">
                <div class="text-item" v-for="(course, index) in targetCertification.courses" :key="index">
                  <div class="item-row row-contents">
                    <div class="item-column">
                      <p class="item-subtitle">{{ course.field }}・필수</p>
                      <h5 class="item-title">{{ course.name }}</h5>
                      <div class="item-meta">
                        <span class="text">{{ course.media }}</span>
                        <span class="text">{{ course.host }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="item-row row-buttons">
                    <button class="kb-btn kb-btn-outline" @click="applyTrain(course.code)"><span class="text">수강신청</span></button>
                  </div>
                </div>
              </template>
            </div>
          </article>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import {certificationConfigs} from '@/assets/js/modules/mypage/mypage-common';
import {computed} from 'vue';
import {useRoute, useRouter} from "vue-router";

export default {
  name: "MobileMyPageCertification",
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const targetCertification = computed(() => {
      const target = certificationConfigs.find(x => x.key === route.params.courseKey);
      return target ? target : {};
    });

    const isFirst = (targetCourse) => {
      const filtered = targetCertification.value.courses.filter(x => x.field === targetCourse.field);
      return filtered.indexOf(targetCourse) === 0;
    }

    const getRowspan = (targetCourse) => {
      return targetCertification.value.courses.filter(x => x.field === targetCourse.field).length;
    }

    const applyTrain = (code) => {
      router.push({name: 'ApplyTrain', query : {'crseMstSn': code}});
    }

    const goBef = () => {
      //router.push({name: 'MyPageMain'});
      history.back()
    }

    return {
      targetCertification,
      getRowspan,
      isFirst,
      goBef,
      applyTrain,
    }
  }
}
</script>
